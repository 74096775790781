// @flow
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import type { Action } from 'aredux/types';

const Button = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  color: blue;
  border: 0.5px solid grey;
  border-radius: 4px;
  width: 80px;
  margin-bottom: 15px;
  margin-top: 15px;
`;

type Props = {
  increment: () => Action,
  decrement: () => Action,
  sum: number,
};

class Redux extends PureComponent<Props> {
  render() {
    return (
      <React.Fragment>
        <div>Sum: {this.props.sum}</div>
        <Button onClick={this.props.increment}>Increase</Button>
        <Button onClick={this.props.decrement}>Decrease</Button>
      </React.Fragment>
    );
  }
}

export default Redux;
