// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import { increment, decrement } from 'aredux/modules/main/actions';
import type { State, Action } from 'aredux/types';

import ReduxTest from './ReduxTest';

type DispatchProps = {
  increment: () => Action,
  decrement: () => Action,
}

const mapStateToProps = (state: State) => ({
  sum: state.main.sum,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => ({
  increment: () => dispatch(increment()),
  decrement: () => dispatch(decrement()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReduxTest);
