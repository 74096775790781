// @flow
import type { MainState, Action } from './types';

export const initialState: MainState = {
  sum: 0,
};

const mainReducer = (state: MainState = initialState, action: Action): MainState => {
  switch (action.type) {
    case 'main/INCREMENT':
      return {
        ...state,
        sum: state.sum + 1,
      };
    case 'main/DECREMENT':
      return {
        ...state,
        sum: state.sum - 1,
      };
    default:
      return state;
  }
};

export default mainReducer;
