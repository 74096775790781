// @flow

import React from 'react';


const Page404 = () => (
  <div>
    404
  </div>
);

export default Page404;
