// @flow

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import createHistory from 'history/createBrowserHistory';
import {
  applyMiddleware,
  compose,
  createStore,
  combineReducers,
} from 'redux';
import { connectRouter, routerMiddleware, ConnectedRouter } from 'connected-react-router';

import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router';


import mainReducer, { mainState } from 'aredux/modules/main';

import EasterEgg from 'pages/EasterEgg';
import EntryPage from 'pages/EntryPage';
import HeyPage from 'pages/HeyPage';
import Page404 from 'pages/Page404';
import ReduxTest from 'pages/ReduxTest';

const history = createHistory();

const store = createStore(
  connectRouter(history)(combineReducers({ main: mainReducer })),
  {
    main: mainState,
  },
  compose(
    applyMiddleware(
      routerMiddleware(history),
    ),
  ),
);

const rootElement: ?HTMLElement = document.getElementById('app');

if (rootElement) {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route exact path="/" component={EntryPage} />
          <Route path="/easter-egg" component={EasterEgg} />
          <Route path="/hey-:name" component={HeyPage} />
          <Route path="/redux-test" component={ReduxTest} />
          <Route path="/" render={Page404} />
        </Switch>
      </ConnectedRouter>
    </Provider>,
    (rootElement),
  );
} else {
  throw Error('Could not find the react root element on the page.');
}
