// @flow

import React, { PureComponent } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  color: blue;
  border: 0.5px solid grey;
  border-radius: 4px;
  width: 80px;
  margin-bottom: 15px;
`;

const Title = styled.div`
  font-size: 32px;
  margin-bottom: 20px;
  text-align: center;
`;

const SubTitle = styled.div`
  font-size: 20px;
  margin-bottom: 15px;
`;

type State = {
  displayPopUp: boolean,
}

class EntryPage extends PureComponent<{}, State> {
  state = {
    displayPopUp: false,
  };

  togglePopUp = () => {
    this.setState(state => ({
      displayPopUp: !state.displayPopUp,
    }));
  }

  render() {
    const { displayPopUp } = this.state;
    return (
      <Container>
        <Title>
          Rob Cron dot in
        </Title>
        <SubTitle>
          Cool domain
        </SubTitle>
        <Button onClick={this.togglePopUp}>
          Click Me
        </Button>
        {displayPopUp
          && (
            <SubTitle>
              Hey JR!
            </SubTitle>
          )
        }
      </Container>
    );
  }
}

export default EntryPage;
