// @flow

import React from 'react';


const EasterEgg = () => (
  <div>
    EasterEgg
  </div>
);

export default EasterEgg;
